/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Hidden, makeStyles, Drawer, Grid, DialogTitle, IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../shared/icons';
import Filter from '../Filter/filter';
import Styles from './filterAndSortButton.styles';
import { CommonButton } from '../../shared/ui/commonButtons';
import { useProductFilterQuery, useProductFilterQueryForCollection } from '../../sevices/service';
import { useFilterContext } from '../../contexts/filter.context';
import { sortData, sizeOrder } from '../../utils/constants';
import { setPreviousPath } from '../../redux/pathReducer';
import { shoppingExperienceColors } from '../../shared/constants';

const useStyles = makeStyles(Styles);

const FilterButton = props => {
  const {
    isMobile,
    productsRefetch,
    setGroupedFilter = () => {},
    groupedFilter,
    handleSortChange,
    sortItem,
    selectedCollectionId,
    isCollectionPage = false,
    categoryName = null,
    products = []
  } = props;

  const {
    appliedFilters,
    setAppliedFilter,
    filterData,
    setFilterData,
    openFilterDrawer,
    setOpenFilterDrawer,
    setDelayLoading,
    retainAppliedFilters
  } = useFilterContext();

  const history = useHistory();

  const [openAccordions, setOpenAccordions] = useState({});
  const { data, refetch } = isCollectionPage
    ? useProductFilterQueryForCollection(selectedCollectionId ? groupedFilter || null : null, selectedCollectionId)
    : useProductFilterQuery(groupedFilter, selectedCollectionId);

  const classes = useStyles();
  const dispatch = useDispatch();
  const toggleDrawer = _open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenFilterDrawer(_open);
  };

  const previousPath = useSelector(state => state.path.previousPath);

  const handleApplyFilter = clear => {
    if (!clear) {
      const groupedData = appliedFilters?.reduce((acc, item) => {
        // eslint-disable-next-line no-underscore-dangle
        const id = item._id || item.id;
        if (acc[id]) {
          if (Array.isArray(item.value)) {
            acc[id].values = [...acc[id].values, ...item.value];
          } else {
            acc[id].values.push(item.value);
          }
        } else {
          acc[id] = {
            _id: id,
            values: Array.isArray(item.value) ? item.value : item.values || [item.value]
          };
        }
        return acc;
      }, {});
      const result = Object.values(groupedData);
      setGroupedFilter(result);
    } else {
      setGroupedFilter([]);
    }
  };

  useEffect(() => {
    handleApplyFilter();
  }, [appliedFilters]);

  useEffect(() => {
    if (data?.productFilter) {
      setFilterData([
        ...sortData,
        ...data.productFilter.reduce((result, currentItem) => {
          let values = currentItem.values.filter(val => val.totalProducts > 0);

          if (currentItem.fieldName === 'variants.labelSize') {
            values = values.sort((a, b) => {
              const indexA = sizeOrder.indexOf(a.label.toLowerCase());
              const indexB = sizeOrder.indexOf(b.label.toLowerCase());
              // Items not in the custom order go to the end
              return (indexA !== -1 ? indexA : Infinity) - (indexB !== -1 ? indexB : Infinity);
            });
          }

          result.push({ ...currentItem, values });
          return result;
        }, [])
      ]);
    }
  }, [data]);

  const FilterContent = () => {
    const clearAll = (canApplyFilter = false) => {
      if (!canApplyFilter) {
        setOpenFilterDrawer(false);
      } else {
        handleApplyFilter(true);
      }
      if (categoryName) {
        setDelayLoading(true);
        setOpenFilterDrawer(true);
        history.push('/catalog', { replace: true });
      }
      dispatch(setAppliedFilter([]));
    };

    return (
      <div role="presentation">
        <Grid className={classes.titleContainer}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenFilterDrawer(false);
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle className={classes.title}>
            <Typography variant="h5SemiBoldInter">Filter</Typography>
          </DialogTitle>
        </Grid>
        <Grid>
          <Filter
            filterData={filterData}
            appliedFilters={appliedFilters}
            productsRefetch={productsRefetch}
            handleApplyFilter={handleApplyFilter}
            clearAll={clearAll}
            openAccordions={openAccordions}
            setOpenAccordions={setOpenAccordions}
            handleSortChange={handleSortChange}
            sortItem={sortItem}
            categoryName={categoryName}
            products={products}
          />
        </Grid>
        <Grid style={{ padding: '20px 16px' }}>
          <CommonButton
            w="100%"
            mt="0px"
            text={`Apply filters (${appliedFilters.length})`}
            isActive
            onClick={() => setOpenFilterDrawer(false)}
          />
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    if (selectedCollectionId) {
      refetch();
    }
  }, [selectedCollectionId, refetch]);

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (previousPath.includes('/product/') || !location.pathname.includes('/product/')) {
        console.log('clearing filters');
        setAppliedFilter([]);
      }
      dispatch(setPreviousPath(history.location.pathname));
    });
    dispatch(setPreviousPath(history.location.pathname));

    return () => unlisten();
  }, [history, previousPath, dispatch]);

  return (
    <>
      <Hidden smDown>
        <Button
          variant="contained"
          size="small"
          endIcon={<FilterListIcon />}
          onClick={toggleDrawer(true)}
          className={classes.filterButtonStyle}
        >
          <Typography variant="body3MediumInter" style={{ color: shoppingExperienceColors.white }}>
            Filter and Sort
          </Typography>
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          variant="contained"
          size="small"
          className={classes.filterButtonStyleMobile}
          onClick={toggleDrawer(true)}
        >
          <FilterListIcon />
        </Button>
      </Hidden>
      <Drawer anchor={isMobile ? 'bottom' : 'right'} PaperProps={{ style: { width: 400 } }} open={openFilterDrawer}>
        <FilterContent
          setGroupedFilter={setGroupedFilter}
          setOpen={setOpenFilterDrawer}
          sortItem={sortItem}
          handleSortChange={handleSortChange}
          categoryName={categoryName}
        />
      </Drawer>
    </>
  );
};

export default FilterButton;
