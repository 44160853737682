import { request, gql } from 'graphql-request';
import { apiRoutes } from './apiRoutes';

export const fetchDistributorCatalogProducts = async (take, skip, appliedFilters, sort = null, productCodes) => {
  const variables = {
    take,
    skip,
    appliedFilters,
    ...(sort ? { sort } : {}),
    productCodes
  };

  const query = gql`
    query GetProducts(
      $take: Int!
      $skip: Int!
      $appliedFilters: [AppliedFiltersInput!]
      $sort: DistributorCatalogSortInput
      $productCodes: [String!]
    ) {
      distributorCatalogProducts(
        take: $take
        skip: $skip
        appliedFilters: $appliedFilters
        sort: $sort
        productCodes: $productCodes
      ) {
        products {
          _id
          name
          canBeExported
          markupOption {
            type
            markupMatrix {
              parameters {
                name
                minimum
                maximum
                type
                value
              }
              markup {
                currency
                value
                pricingUnitOfMeasurement
              }
            }
          }
          shipping {
            type
            currency
            value
            unitOfMeasure
          }
          color {
            hex
            standardColorName
            colorName
          }
          decorationOptions
          categories {
            name
            subCategories
          }
          productCode
          media {
            name
            url
            mediaCategory
            color
          }
          pricingOption {
            pricingMatrix {
              parameters {
                minimum
                maximum
              }
              price {
                value
              }
            }
          }
          hazmatDetails {
            isHazmat
          }
        }
        totalProducts
      }
    }
  `;

  return request(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`, query, variables, {
    'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
  }).then(data => {
    return data;
  });
};

export const fetchCollectionsDetails = async () => {
  const query = gql`
    query GetCollections {
      productCollections {
        collections {
          _id
          distributorId
          media {
            color
            name
            tags {
              value
            }
            type
            url
            width
            mediaCategory
            height
            device
          }
          name
          products {
            productCode
            sequence
          }
          sequence
          slug
          status
        }
      }
    }
  `;

  return await request(
    `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`,
    query,
    null,
    {
      'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
    }
  );
};

export const fetchCollectionProducts = async (collectionId = null, take, skip, appliedFilters = [], sort = null) => {
  if (!collectionId) {
    return;
  }
  const variables = {
    take,
    skip,
    collectionId,
    appliedFilters,
    sort
  };

  const query = gql`
    query GetCollectionsProducts(
      $take: Int!
      $skip: Int!
      $collectionId: String!
      $appliedFilters: [AppliedFiltersInput!]
      $sort: DistributorCatalogSortInput
    ) {
      distributorCatalogProducts(
        take: $take
        skip: $skip
        collectionId: $collectionId
        appliedFilters: $appliedFilters
        sort: $sort
      ) {
        products {
          _id
          name
          canBeExported
          markupOption {
            type
            markupMatrix {
              parameters {
                name
                minimum
                maximum
                type
                value
              }
              markup {
                currency
                value
                pricingUnitOfMeasurement
              }
            }
          }
          shipping {
            type
            currency
            value
            unitOfMeasure
          }
          color {
            hex
            standardColorName
            colorName
          }
          decorationOptions
          categories {
            name
            subCategories
          }
          productCode
          media {
            name
            url
            mediaCategory
            color
          }
          pricingOption {
            pricingMatrix {
              parameters {
                minimum
                maximum
              }
              price {
                value
              }
            }
          }
          hazmatDetails {
            isHazmat
          }
        }
        totalProducts
      }
    }
  `;

  return await request(
    `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`,
    query,
    variables,
    {
      'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
    }
  ).then(data => {
    return data?.distributorCatalogProducts;
  });
};

export const fetchProductFilter = async (appliedFilters, collectionId) => {
  const variables = {
    appliedFilters,
    collectionId
  };
  const query = gql`
    query GetFilters($appliedFilters: [AppliedFiltersInput!], $collectionId: String) {
      productFilter(appliedFilters: $appliedFilters, collectionId: $collectionId) {
        _id
        name
        fieldName
        inputType
        multiselect
        type
        values {
          label
          value
          parentFieldValue
          totalProducts
        }
      }
    }
  `;

  return request(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`, query, variables, {
    'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
  }).then(data => {
    return data;
  });
};

export const fetchDistributorCatalogProductsForCart = async productCodes => {
  const variables = {
    productCodes
  };

  const query = gql`
    query GetCart($productCodes: [String!]) {
      distributorCatalogCartItems(productCodes: $productCodes) {
        _id
        name
        productCode
        brand
        minimumOrderQuantity
        markupOption {
          type
          markupMatrix {
            parameters {
              name
              minimum
              maximum
              type
              value
            }
            markup {
              currency
              value
              pricingUnitOfMeasurement
            }
          }
        }
        shipping {
          type
          currency
          value
          unitOfMeasure
        }
        media {
          url
          mediaCategory
        }
        hazmatDetails {
          isHazmat
        }
        variants {
          media {
            name
            url
            mediaCategory
          }
          color {
            standardColorName
            hex
          }
          pricingOption {
            pricingMatrix {
              parameters {
                name
                minimum
                maximum
                type
                value
              }
              price {
                value
              }
            }
          }
        }
        defaultVariant {
          media {
            name
            url
            mediaCategory
          }
          color {
            standardColorName
            hex
          }
          pricingOption {
            pricingMatrix {
              parameters {
                name
                minimum
                maximum
                type
                value
              }
              price {
                value
              }
            }
          }
        }
      }
    }
  `;

  return request(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`, query, variables, {
    'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
  }).then(data => {
    return data;
  });
};

export const fetchSubcategoriesByCategory = async category => {
  const variables = {
    category
  };

  const query = gql`
    query GetSubcategoriesByCategory($category: String!) {
      subCategoriesByCategory(category: $category) {
        subCategories
      }
    }
  `;

  return request(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`, query, variables, {
    'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
  }).then(data => {
    return data;
  });
};
