import { request, gql } from 'graphql-request';
import { MAX_PRODUCT_PER_PAGE } from '../utils/constants';
import { apiRoutes } from './apiRoutes';

export const fetchSearchResults = async (searchString, take = MAX_PRODUCT_PER_PAGE, skip = 0) => {
  const variables = {
    searchString,
    skip,
    take
  };

  const GET_SEARCH_RESULT_PRODUCTS = gql`
    query GetSearchResultProducts($searchString: String, $skip: Int, $take: Int) {
      searchDistributorCatalogProducts(searchString: $searchString, skip: $skip, take: $take) {
        products {
          _id
          name
          canBeExported
          markupOption {
            type
            markupMatrix {
              parameters {
                name
                minimum
                maximum
                type
                value
              }
              markup {
                currency
                value
                pricingUnitOfMeasurement
              }
            }
          }
          shipping {
            type
            currency
            value
            unitOfMeasure
          }
          color {
            hex
            standardColorName
            colorName
          }
          decorationOptions
          categories {
            name
            subCategories
          }
          productCode
          media {
            name
            url
            mediaCategory
            color
          }
          pricingOption {
            pricingMatrix {
              parameters {
                minimum
                maximum
              }
              price {
                value
              }
            }
          }
          hazmatDetails {
            isHazmat
          }
        }
        totalProducts
      }
    }
  `;

  return request(
    `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`,
    GET_SEARCH_RESULT_PRODUCTS,
    variables,
    {
      'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
    }
  ).then(data => {
    return data?.searchDistributorCatalogProducts;
  });
};
