/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Helmet } from '../../components/shared';
import seoTags from '../../apis/seoTags';
import Breadcrumb from '../components/Breadcrumb/breadcrumb';
import TitleHeader from '../components/TitleHeader/titleHeader';
import Product from '../components/Product/product';
import Pagination from '../components/Pagination/pagination';
import Banner from '../components/Banner/banner';
import MembershipHand from '../assets/images/products/membership_hand.png';
import SubCategories from '../components/SubCategoriesHeader/subCategoriesHeader';
import { PageTitle, TitleFormatter, HandleNextPage, HandlePreviousPage } from '../shared/ui/common';
import { PRODUCT_DETAILS } from '../assets/productData';
// eslint-disable-next-line import/no-named-as-default
import Modal from '../shared/ui/modal';
import CartPopup from '../components/CartPopup/cartPopup';
import styles from './catalog.styles';
import PaginationButton from '../shared/ui/paginationButton';
import { getBreadCrumbs } from '../shared/helper';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import { useFilterContext } from '../contexts/filter.context';
import { FILTER_FIELD_NAMES } from '../utils/constants';
import { fetchSubcategoriesByCategory } from '../apis';
import { getRouteMetadata } from '../utils/getRouteMetadata';
import BannerSubtitleText from '../components/Banner/bannerSubtitleText';
import { prepareDataForCartPopup } from '../utils/cartUtil';

const useStyles = makeStyles(styles);

const Catalog = props => {
  const location = useLocation();
  const {
    isTouchDevice,
    isMobile,
    isCategoryPage,
    productList,
    currentPage,
    setCurrentPage,
    skip,
    setLoadProductsByPageNumber,
    categoryName,
    subCategoryName,
    productsRefetch,
    groupedFilter,
    setGroupedFilter,
    handleSortChange,
    sortItem,
    isLoading,
    isFetched,
    loadedProducts,
    setLoadedProducts,
    setIsLoading
  } = props;
  const classes = useStyles();
  const MAX_PRODUCT_PER_PAGE = 20;
  const [renderedProducts, setRenderedProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(null);
  const [progress, setProgress] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [addedProductsToCartCount] = useState(1);
  const [addedProductsToCart, setAddedProductsToCart] = useState(null);
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [isShowPreviousButton, setIsShowPreviousButton] = useState(false);

  const query = new URLSearchParams(location.search);
  const searchTerm = query.get('q');
  const products = [PRODUCT_DETAILS];

  const { data: subCategoriesApiResp } = useQuery(
    ['data', categoryName],
    () => fetchSubcategoriesByCategory(categoryName),
    {
      enabled: !!categoryName
    }
  );
  const { appliedFilters, setAppliedFilter, filterData } = useFilterContext();

  useEffect(() => {
    setProgress((renderedProducts.length / totalProducts) * 100);
  }, [totalProducts]);

  const showMoreHandler = () => {
    setIsLoading(true);
    const page = HandleNextPage(currentPage);
    if (renderedProducts.length <= totalProducts) setCurrentPage(page);
  };

  React.useEffect(() => {
    // Fetch initial products when `renderedProducts` changes
    if (productList?.distributorCatalogProducts?.products) {
      const _loadedProducts = {
        ...loadedProducts,
        ...{ [currentPage]: productList?.distributorCatalogProducts?.products }
      };
      setLoadedProducts(_loadedProducts);
      const loadedPages = Object.keys(_loadedProducts);
      if (loadedPages.length) {
        const isPreviousDataNotLoaded = loadedPages[0] - 1 !== 0;
        setIsShowPreviousButton(isPreviousDataNotLoaded);
        setRenderedProducts(Object.values(_loadedProducts).flat());
      }
    }
    setIsLoading(false);
  }, [productList]);

  const showPreviousHandler = () => {
    const page = HandlePreviousPage(currentPage);
    if (page === 1) setLoadProductsByPageNumber(false);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (searchTerm) {
      const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTotalProducts(filteredProducts.length);
    } else {
      setTotalProducts(productList?.distributorCatalogProducts?.totalProducts);
    }
  }, [searchTerm]);

  useEffect(() => {
    setProgress((renderedProducts.length / totalProducts) * 100);
  }, [renderedProducts]);

  const onClose = () => {
    setAddedProductsToCart(null);
    setOpenModal(false);
  };

  const onOpen = product => {
    setAddedProductsToCart(prepareDataForCartPopup(product));
    setOpenModal(true);
  };

  const PATHS = getBreadCrumbs();

  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck?.isPlatinum;
  const metadata = getRouteMetadata();

  useEffect(() => {
    if (filterData?.length && categoryName) {
      const currentAppliedFilters = [...appliedFilters];
      const categoryFilter = filterData.find(filterItem => filterItem.fieldName === FILTER_FIELD_NAMES.CATEGORY);

      if (categoryFilter) {
        const valueObj = {
          value: categoryName,
          label: categoryName
        };

        const index = currentAppliedFilters.findIndex(
          appliedFilterItem => appliedFilterItem._id === categoryFilter._id
        );

        if (index === -1 && valueObj?.value) {
          currentAppliedFilters.push({ _id: categoryFilter._id, ...valueObj });
          setAppliedFilter([...currentAppliedFilters]);
        }
      }
    }
  }, [categoryName, filterData]);

  useEffect(() => {
    if (isFetched && productList?.distributorCatalogProducts) {
      setTotalProducts(productList.distributorCatalogProducts.totalProducts);
    }
  }, [productList, isFetched, categoryName]);

  useEffect(() => {
    if (filterData?.length && subCategoryName) {
      const currentAppliedFilters = [...appliedFilters];
      const subCategoryFilter = filterData.find(filterItem => filterItem.fieldName === FILTER_FIELD_NAMES.SUB_CATEGORY);

      if (subCategoryFilter) {
        const valueObj = {
          value: subCategoryName,
          label: subCategoryName
        };

        const index = currentAppliedFilters.findIndex(
          appliedFilterItem => appliedFilterItem._id === subCategoryFilter._id
        );

        if (index === -1 && valueObj?.value) {
          currentAppliedFilters.push({ _id: subCategoryFilter._id, ...valueObj });
          setAppliedFilter([...currentAppliedFilters]);
        }
      }
    }
  }, [subCategoryName, filterData]);

  useEffect(() => {
    if (subCategoriesApiResp?.subCategoriesByCategory) {
      setSubCategoriesList(subCategoriesApiResp.subCategoriesByCategory.subCategories);
    }
  }, [subCategoriesApiResp]);

  return (
    <div>
      {metadata ? (
        <Helmet
          tags={{
            title: metadata?.title,
            description: metadata?.description
          }}
        />
      ) : (
        <Helmet tags={seoTags.allSwagPage} />
      )}
      <Grid container className={classes.gridContainer}>
        <Modal
          title="Product added to cart"
          open={openModal}
          onClose={onClose}
          innerClasses={{ paper: classes.dialog }}
        >
          <CartPopup
            details={addedProductsToCart}
            isTouchDevice={isTouchDevice}
            isMobile={isMobile}
            addedProductsToCartCount={addedProductsToCartCount}
            onClose={onClose}
          />
        </Modal>
        <Breadcrumb routes={PATHS} />
        {/* <SearchResults matchedItems={matchedItems} /> */}
        <TitleHeader
          title={
            categoryName
              ? subCategoryName
                ? `${TitleFormatter(categoryName)} ${TitleFormatter(subCategoryName)}`
                : TitleFormatter(categoryName)
              : PageTitle(location)
          }
          showFilter={!(isCategoryPage && !isMobile)}
          totalProducts={totalProducts}
          isMobile={isMobile}
          productsRefetch={productsRefetch}
          groupedFilter={groupedFilter}
          setGroupedFilter={setGroupedFilter}
          handleSortChange={handleSortChange}
          sortItem={sortItem}
          products={renderedProducts}
          categoryName={categoryName}
        />

        {!isMobile && isCategoryPage && (
          <SubCategories
            categoryName={categoryName}
            subCategoryName={subCategoryName}
            subCategoriesList={subCategoriesList}
            groupedFilter={groupedFilter}
            setGroupedFilter={setGroupedFilter}
            handleSortChange={handleSortChange}
            sortItem={sortItem}
          />
        )}

        {isShowPreviousButton && (
          <Grid style={{ marginBottom: 20, justifyContent: 'center', width: '100%', display: 'inline-flex' }}>
            {' '}
            <PaginationButton title="Show previous" showMore={false} showPreviousHandler={showPreviousHandler} />{' '}
          </Grid>
        )}
        <Grid container spacing={2}>
          {renderedProducts.map(product => (
            <Grid
              item
              xs={6}
              md={3}
              sm={6}
              lg={3}
              key={product.id} // Use a unique property for the key
              className={classes.gridItemStyle}
            >
              <Product
                details={product}
                isTouchDevice={isTouchDevice}
                isMobile={isMobile}
                onOpen={onOpen}
                addedProductsToCartCount={addedProductsToCartCount}
                category={categoryName}
                subCategory={subCategoryName}
              />
            </Grid>
          ))}
          {isLoading === true &&
            new Array(8).fill('').map((_, ind) => (
              <Grid item xs={6} md={3} sm={6} lg={3} key={`product-list-item-${ind}`} className={classes.gridItemStyle}>
                <Product
                  details={null}
                  isTouchDevice={isTouchDevice}
                  isMobile={isMobile}
                  onOpen={onOpen}
                  addedProductsToCartCount={addedProductsToCartCount}
                  category={categoryName}
                  subCategory={subCategoryName}
                />
              </Grid>
            ))}
        </Grid>

        <Pagination
          progress={progress}
          renderedProducts={renderedProducts.length}
          totalProducts={totalProducts}
          showMoreHandler={showMoreHandler}
          isLoading={isLoading}
        />
      </Grid>
      {!PlatinumMembershipTier && (
        <Banner
          image={`${MembershipHand}`}
          title="Platinum Members Get 5% Off All Products"
          mobileTitle="Platinum Members Get 5% Off All Products"
          subtitle={<BannerSubtitleText />}
          buttonLabel="Upgrade to Platinum"
          buttonLink="https://www.swagup.com/membership"
          highlightedWord="Platinum"
          isMobile={isMobile}
        />
      )}
    </div>
  );
};
export default Catalog;
